<template>
  <BlogList v-bind:contentsList='contents' recentPost=false showCategory=true />
</template>

<script>
// @ is an alias to /src
import GetContent from '@/functions/getContents.js'
import BlogList from '@/components/BlogList.vue'

export default {
  name: 'CategoryList',
  components: {
    BlogList,
  },
  head() {
    return {
      title: {
          inner: this.title,
          separator: '|',
          complement: 'k-weather.com',
      },
    }
  },
  methods: {
    async get() {
      var getContent = await GetContent.GetContent.build();
      this.contents = await getContent.getCategory(this.$route.params.tag);
    }
  },
  mounted () {
    this.get();
  },
  beforeRouteUpdate( to, from, next) {
    this.get().then(()=>{
      this.title = this.$route.params.tag;
      this.$emit('updateHead');
    })
    next();
  },
  data () {
    return {
      contents: [],
      title: this.$route.params.tag,
    }
  }
}
</script>