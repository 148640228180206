<template>
  <div>ページが存在しません</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: '404NotFound',
}
</script>