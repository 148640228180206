import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueHead from 'vue-head'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.use(VueHead)
Vue.use(VueGtag, {
  config: {id: 'G-B7J2NVT8MT'},
  pageTrackerScreenviewEnabled: true,
}, router);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
