import axios from 'axios'

const GetContent = class {
    constructor() {
        this.contents = [];
    }
    
    static async build() {
        const getContent = new GetContent();
        await getContent.getContents();
        return getContent;
    }
    
    async getContents() {
        var response = await axios.get('/contents.json')
        this.contents = response.data;
    }

    async getContent(id) {
        return this.contents.contents.find((content) => content.id == id);
    }

    async getCategory(tag) {
        return this.contents.contents.filter(function(content) {
            if(content.category.find((category) => category.name == tag)){
                return true;
            }
        });
    }
}

export default {
    GetContent
}