import axios from 'axios'

const GetCategory = class {
    constructor() {
        this.categories = [];
    }
    
    static async build() {
        const getCategory = new GetCategory();
        await getCategory.getList();
        return getCategory;
    }
    
    async getList() {
        var response = await axios.get('/categories.json')
        this.categories = response.data.categories;
    }
}

export default {
    GetCategory
}