<template>
  <v-container>
    <v-row>
    <v-col cols=2 :class='getCategoryVisibility()' >
      <v-card class="sticky pr-4">
      <v-row><v-col class="text-end"><h2>カテゴリー</h2></v-col></v-row>
      <v-row v-for='tag in category.categories' :key='tag'>
        <v-col  class="d-flex justify-end">
        <v-btn text :to="/category/ + tag">{{tag}}</v-btn>
        </v-col>
      </v-row>
      </v-card>
    </v-col>
    <v-col :cols='blogCols'>
      <v-row>
        <v-col cols="12" :class='getVisibility()'><h1>Recent Posts</h1></v-col>
        <v-col cols="12" sm="6" md="4" v-for='content in contentsList' :key='content.id'>
          <v-card shaped  max-width="500" class='mx-auto' v-bind:to='/blog/+content.id'>
            <v-img
              class="mx-auto"
              height="200"
              cover
              v-bind:src='content.banner.url'
              ></v-img>
            <v-row>
              <v-col cols='auto'>
            <v-card-title>{{content.title}}</v-card-title>
            </v-col>
            <v-col>
            <v-card-text class="text-right">{{content.date.slice(0,10)}}</v-card-text>
            </v-col>
            </v-row>
            <v-divider class="mx-4" />
              <v-card-text>{{getSummary(content.main)}}...</v-card-text>
            <v-divider class="mx-4" />
            <v-container>
            <v-row class=''>
              <v-col v-for='tag in content.category' :key='tag'>
                <v-btn 
                  depressed
                  v-bind:to='/category/+tag.name'
                  >{{tag.name}}</v-btn>
              </v-col>
            </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
  </v-container>
</template>


<style>
.sticky {
  position: sticky;
  top: 100px;
}
</style>

<script>
import GetCategory from '@/functions/getCategory.js'

export default {
  name: 'BlogList',
  props: ['contentsList', 'recentPost', 'showCategory'],
  data () {
    return ({
      contents: this.contentsList,
      visibility: 'd-none',
      category: [],
      blogCols: 12,
    })
  },
  methods: {
    async getCategory() {
      this.category = await GetCategory.GetCategory.build();
    },
    getSummary(bodyText) {
      var summary = bodyText.replace(RegExp('<[A-Za-z0-9"= /]*>', 'g'),'').substring(0,30);
      for(var i = summary.length; i < 30; i++){
        summary += ' ';
      }
      return summary;
    },
    getVisibility(){
      if(this.recentPost == 'true'){
        return 'd-block'
      }else{
        return 'd-none'
      }
    },
    getCategoryVisibility(){
      if(this.showCategory == 'true'){
        switch(this.$vuetify.breakpoint.name){
          case 'xs':
          case 'sm':
            this.blogCols = 12;
            return 'd-none'
          default:
            this.blogCols = 10;
            return 'd-block';
        }
      }else{
        this.blogCols = 12;
        return 'd-none';
      }
    }
  },
  mounted: function () {
    this.getCategory();
  },
}
</script>

