<template>
    <v-card outlined max-width='600' width='80%'>
      <v-card-title class='justify-center'>{{title}}</v-card-title>
      <v-card-text v-html='content.main'></v-card-text>
    </v-card>

</template>

<script>
// @ is an alias to /src
import Export from '@/functions/readMd.js'

export default {
  name: 'MyBlogPage',
  head() {
    return {
      title: {
          inner: this.title,
          separator: '|',
          complement: 'k-weather.com',
      },
      meta: [
        { name: 'description', content: this.description},
      ],
    }
  },
  data () {
    return {
      content: {
        main : 
        ''
      },
      title: '',
      description: '',
    }
  },
  methods: {
    getHtml: async function() {
        this.content.main = await Export.readMdFile('/md/' + this.$route.params.page + '.md');
    }
  },
  created () {
    this.getHtml();
  }
}
</script>