import axios from 'axios'

const GetPhoto = class {
    constructor() {
        this.photos = [];
    }
    
    static async build() {
        const getPhoto = new GetPhoto();
        await getPhoto.getList();
        return getPhoto;
    }
    
    async getList() {
        var response = await axios.get('/photos.json')
        this.photos = response.data.contents;
    }
}

export default {
    GetPhoto
}