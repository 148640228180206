<template>
  <v-footer
    padless
    color=#82eaff
  >
    <v-container>
      <v-row>
        <v-col cols=3 class="d-none d-md-block"> 
        </v-col>
        <v-col cols=3 class="d-none d-md-block text-center">
          <v-btn href="mailto:info@k-weather.com"
            text
          >
            info@k-weather.com</v-btn>
        </v-col>
        <v-col cols=6 md=3 class="d-flex justify-center">
          <v-btn 
            v-for="icon in icons"
            :key="icon.link"
            :href='icon.link'
            class="mx-4 d-none d-sm-block my-1"
            icon>
            <v-icon size="24px">{{icon.iconfont}}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols=12 sm=6 md=3 class="text-center">
          <p class="text-body-2 text-md-body-1 my-1">2022- k-weather.com</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  name: 'MyFooter',
  data () {
    return ({
      icons: [
        {iconfont:'mdi-twitter', link:'https://twitter.com/ChrysanWeather'},
        {iconfont:'mdi-github', link:'https://github.com/k-weather'},
        {iconfont:'mdi-email-outline', link:'mailto:info@k-weather.com'},
      ]
    })
  },
}
</script>
