<template>
  <!-- <ReadXml /> -->
  <v-container>
    <v-row>
    <v-carousel 
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      cycle=true
      >
        <v-carousel-item 
          v-for='(item,i) in items'
          :key='i'
          :src='item.src'
          contain>
        </v-carousel-item>
    </v-carousel>
    </v-row>
    <AboutMe />
    <v-row>
      <v-card outlined>
        <RecentPost v-bind:contentsNum='maxNum()' pShowCategory=false />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import AboutMe from '@/components/About.vue'
import RecentPost from '@/views/Blog.vue'

export default {
  name: 'MyHome',
  head: {
    title: {
      inner: 'HOME',
      separator: '|',
      complement: 'k-weather.com',

    },
    meta: [
        { property: 'og.title', content: 'HOME' },
    ]
  },
  data () {
      return {
          width: window.innerWidth,
          items: [
              {
                  src: 'image/top1.jpg',
              },
              {
                  src: 'image/top2.jpg',
              },
              {
                  src: 'image/top3.jpg',
              },
          ]
      }
  },
  methods: {
      handleResize() {
          this.width = window.innerWidth;
      },
      maxNum() {
            switch(this.$vuetify.breakpoint.name){
                case 'xs':
                case 'sm':
                    return 2;
                default :
                    return 3;
          }
      }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  components: {
    RecentPost,
    AboutMe,
  }
}
</script>