<template>
<div>
  <v-app-bar 
    color=#82eaff
    app
  >
    <v-app-bar-nav-icon app @click="onOffDrawer()" :class='navicon' ></v-app-bar-nav-icon>
      <v-app-bar-title>
        <router-link to="/">
        <v-img src='../image/logo.png' max-width=200 />
        </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div v-bind:class="visibility">
        <v-btn 
          text 
          x-large 
          v-for='item in items' :key="item.title" :to="item.link"
        >{{item.title}}</v-btn>
      </div>
    </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    app
    relative
  >
    <v-list>
      <v-list-item-group>
        <v-list-item v-for='item in items' :key="item.title" :to="item.link">
          <v-list-item-title>{{item.title}}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>

export default {
  name: 'MyNavigation',
  data () {
    return ({
      drawer: false,
      visibility: 'd-none',
      navicon: 'd-block',
      items: [
        {title: 'HOME', link:'/'},
        {title: 'Photo', link:'/photo'},
        {title: 'Blog', link:'/blog/'},
      ],
    })
  },
  methods: {
    updateVisibility() {
      if(this.drawer){
        this.visibility = "d-none";
      }else{
        this.visibility = "d-block";
      }
    },
    onOffDrawer () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          this.drawer = !this.drawer;
          this.navicon = 'd-block';
          this.visibility = 'd-none';
          break;
        default:
          this.drawer = false;
          this.visibility = 'd-block';
          if(!this.drawer) this.navicon = 'd-none';
          break;
      }
      return this.drawer;
    },
    resizeDrawer () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          this.navicon = 'd-block';
          this.visibility = 'd-none';
          break;
        default:
          this.drawer = false;
          this.visibility = 'd-block';
          // if(!this.drawer) this.navicon = 'd-none';
          break;
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.resizeDrawer);
    this.resizeDrawer();
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resizeDrawer)
  }
}
</script>
