<template>
  <v-row class="justify-center py-3 my-3">
    <v-card outlined>
      <v-card-title>
        <h2>About</h2>
      </v-card-title>
      <v-card-text>
        <h2>このサイトについて</h2>
        <p>
        こんにちは、栗山です。気象予報士試験合格を目指して勉強中のエンジニアです。<br>
        何かに必要というわけではなく完全に趣味の延長で勉強しているので
        モチベーションを継続させる意味も込めてブログを書いていこうと思っています。<br>
        間違ったことを書いていることもあると思いますがもし気づいたらご指摘いただけますと幸いです。
        </p>
        <h2>コンテンツについて</h2>
        <p>
        気象予報士試験に関することだけでなく趣味関連の話も適当に書いていこうと思っています。<br>
        最近はこのサイトを立ち上げるのにAWSとかヘッドレスCMSとかSPAとか勉強しているのでそのこととか、
        あとはオーディオとかビールとかサッカーとかそんなことも書いていこうかなと...
        </p>   
      </v-card-text> 
    </v-card>
  </v-row>
</template>

<script>

export default {
  name: 'AboutMe',
  // props: ['contentsList', 'recentPost'],
  data () {
    return ({
    })
  },
  methods: {
    template() {
      
    }
  },
}
</script>
