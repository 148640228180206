<template>
  <v-container>
  <v-row>
    <v-col cols=12 sm=6 lg=4 v-for='photo in photoList' :key='photo.id'>
      <v-dialog v-model='dialog' :max-width='modalWidth()'>
        <template v-slot:activator='{on, attrs}'>
          <v-card v-bind='attrs' v-on='on'>
            <v-img 
              :lazy-src='photo.photo.url + "?w=100"'
              :src='photo.photo.url + "?w=" + getWidth()' contain>
              <v-card color=#000000af dark class="description-pc" :width="getWidth()">
                <v-row class="px-3">
                <div class="col text-center">
                  {{photo.title}}
                </div>
                <div class="col-auto">
                  {{photo.date.substring(0,10)}}
                </div>
                </v-row>
              </v-card>
            </v-img>
          </v-card>
        </template>
        <v-card>
          <v-img 
            :lazy-src='photo.photo.url + "?w=300"'
            :src='photo.photo.url'
            contain
            >
            <slot name='placeholder'>
              <v-card color=#000000af dark class="description-pc d-none d-md-block"  :width="modalWidth()">
                <div class="px-3 py-1">
                  {{photo.description}}
                </div>
              </v-card>
            </slot>
          </v-img>
        </v-card>
        <v-card color=#000000af dark class="d-block d-md-none"  :width="modalWidth()">
          <div class="px-3 py-1">
            {{photo.description}}
          </div>
        </v-card>
      </v-dialog>
    </v-col>
    </v-row>
  </v-container>
</template>

<style>
.description-pc {
  position: absolute;
  bottom: 0;
}

.description-sp {
}
</style>

<script>
import getPhoto from '@/functions/getPhotos.js'

export default {
  name: 'PhotoList',
  // props: ['contentsList', 'recentPost'],
  data () {
    return ({
      description: "description-pc",
      photoList: [],
      height: window.innerHeight * 0.8
    })
  },
  methods: {
    async getPhotoList() {
      var temp = await getPhoto.GetPhoto.build();
      this.photoList = temp.photos;
    },
    getWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 450;
        case 'sm':
          return 600;
        default:
          return 800;
      }
    },
    modalWidth(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 600;
        case 'sm':
          return 750;
        case 'md':
          return 1000;
        case 'lg':
          return 1250;
        case 'xl':
          return 1250;
        default:
          return 1000;
      }
    },
    updateDescriptionPos(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          this.description = "description-sp";
          break;
        default:
          this.description = "description-pc";
          break;
      }
    }
  },
  mounted: function() {
    this.getPhotoList();
    this.updateDescriptionPos();
  }
}
</script>
