<template>
  <BlogList v-bind:contentsList='contents' recentPost=true :showCategory='showCategory' />
</template>

<script>
// @ is an alias to /src
import GetContent from '@/functions/getContents.js'
import BlogList from '@/components/BlogList.vue'

export default {
  name: 'ContentList',
  components: {
    BlogList,
  },
  head() {
    return {
      title: {
          inner: this.title,
          separator: '|',
          complement: 'k-weather.com',
      },
    }
  },
  methods: {
    async get() {
      var getContent = await GetContent.GetContent.build();
      
      if(this.maxNum == undefined){
        this.contents = getContent.contents.contents; 
        this.maxNum = this.contents.length;
      }else{
        this.contents = getContent.contents.contents; 
        this.contents = this.contents.slice(0,this.maxNum);
      }
      if(this.pShowCategory != undefined){
        this.showCategory = this.pShowCategory;
      }
    },
  },
  mounted () {
    this.get()
  },
  props: ['contentsNum', 'pShowCategory'],
  data () {
    return {
      contents: [],
      maxNum: this.contentsNum,
      title: 'Blog',
      showCategory: 'true'
    }
  }
}
</script>