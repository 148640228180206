<template>
  <PhotoList />
</template>

<script>
// @ is an alias to /src
import PhotoList from '@/components/PhotoList.vue'

export default {
  name: 'MyPhoto',
  head() {
    return {
      title: {
          inner: 'Photo',
          separator: '|',
          complement: 'k-weather.com',
      },
    }
  },
  components: {
    PhotoList,
  }
}
</script>