import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/Home.vue'
import PhotoList from '../views/Photo.vue'
import BlogList from '../views/Blog.vue'
import BlogPage from '../views/BlogPage.vue'
import CategoryList from '../views/CategoryList.vue'
import Page404 from '../views/Error.vue'
import StockPage from '../views/StockPage.vue'

Vue.use(VueRouter)

// const Home = {template: '<div>Router Home</div>'}
// const Photo = {template: '<div>Router Photo</div>'}
// const Blog = {template: '<div>Router Blog</div>'}

const routes = [
    {path: '/', name: 'home', component : HomePage},
    {path: '/photo', name: 'photo', component : PhotoList},
    {path: '/blog', name: 'blog', component : BlogList},
    {path: '/stocks/:page', name: 'stocks', component : StockPage},
    {path: '/materialmpd/:page', name: 'mpd', component : StockPage},
    {path: '/blog/:id', name: 'blogPage', component : BlogPage},
    {path: '/category/:tag', name: 'blogCategory', component : CategoryList},
    {path: '/*', name: '404NotFound', component : Page404},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router;
