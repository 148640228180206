<template>
    <v-card outlined max-width='600' width='80%'>
      {{this.title.inner}}
      <v-card-title class='justify-center'>{{content.title}}</v-card-title>
      <v-img v-bind:src='content.banner.url' max-width='600'/>
      <v-card-text v-html='content.main'></v-card-text>
    </v-card>

</template>

<script>
// @ is an alias to /src
import GetContent from '@/functions/getContents.js'

export default {
  name: 'MyBlogPage',
  head() {
    return {
      title: {
          inner: this.title,
          separator: '|',
          complement: 'k-weather.com',
      },
      meta: [
        { name: 'description', content: this.description},
      ],
    }
  },
  methods: {
    async get() {
      var getContent = await GetContent.GetContent.build();
      this.content = await getContent.getContent(this.$route.params.id);
    },
    async updateTitle() {
      this.get()
      .then(() => {
        this.title = this.content.title;
        this.description = this.getSummary(this.content.main, 200);
        this.$emit('updateHead')
      });
    },
    getSummary(bodyText, length) {
      var summary = bodyText.replace(RegExp('<[A-Za-z0-9"= /]*>', 'g'),'').substring(0,length);
      for(var i = summary.length; i < length; i++){
        summary += ' ';
      }
      return summary;
    },
  },
  created () {
    this.updateTitle();
  },
  data () {
    return {
      content: [],
      title: '',
      description: '',
    }
  },
}
</script>