<template>
  <v-app>
    <MyNavigation />
    <v-main> 
      <v-container>
        <v-row >
          <v-col cols="12" class='d-flex justify-center'>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <MyFooter />
  </v-app>
</template>

<script>
import MyNavigation from './components/Navigation.vue'
import MyFooter from './components/Footer.vue'
// import ReadXml from './components/ReadXml.vue'

export default {
  components: {
    MyNavigation,
    MyFooter,
    // ReadXml,
  },
}
</script>
