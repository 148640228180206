import { marked } from 'marked';
import axios from 'axios';
// import matter from 'gray-matter';

async function readMdFile(filePath) {
    var response = await axios.get(filePath);
    return   marked.parse(response.data);
}

export default {
    readMdFile,
}
